import SDK3DVerse_ExtensionInterface from 'ExtensionInterface';

//------------------------------------------------------------------------------
export default class SDK3DVerse_ClientDisplay extends SDK3DVerse_ExtensionInterface
{
    //--------------------------------------------------------------------------
    constructor(sdk)
    {
        super(sdk, 'ClientDisplay');

        this.overlayCanvas                  = document.createElement('canvas');
        this.context                        = this.overlayCanvas.getContext("2d");

        this.activeClients                  = [];
        this.visibleClientsPerViewport      = [];
        this.isExtensionEnabled             = true;

        this.clientsRadius                  = 12;
        this.clientsRadiusMin                = 0;
        this.clientsRadiusMax                = Infinity;

        this.isMounted                      = false;

        this.hoveredClientUUIDs             = new Set();

        this.lastMouseMoveEvent             = null;
    }

    //--------------------------------------------------------------------------
    async onInit()
    {
        console.log(`SDK3DVerse_ClientDisplay onInit`);
        this.updateDisplay();
        this.sdk.notifier.on('onDisplayReady', this.updateDisplay);
    }

    //--------------------------------------------------------------------------
    updateDisplay = () =>
    {
        // Unregister previous listeners
        if(this.canvas)
        {
            this.canvas.removeEventListener('click', this.onMouseClick, false);
            this.canvas.removeEventListener('mousemove', this.onMouseMove, false);
        }

        this.canvas = this.sdk.streamerConfig.display.canvas;

        if(!this.canvas)
        {
            return;
        }

        if(!this.isMounted)
        {
            this.isMounted = true;
            this.listenEvents();
        }

        this.originalContext    = this.canvas.getContext("2d");
        this.onCanvasResized(this.canvas.clientWidth, this.canvas.clientHeight);

        this.canvas.addEventListener('click', this.onMouseClick, false);
        this.canvas.addEventListener('mousemove', this.onMouseMove, false);
    }

    //--------------------------------------------------------------------------
    listenEvents()
    {
        this.sdk.notifier.on('onFrameRendered', this.onFrameRendered);
        this.sdk.notifier.on('OnCamerasUpdated', this.onCameraUpdated);
        this.sdk.notifier.on('onCanvasResized', this.onCanvasResized);
    }

    //--------------------------------------------------------------------------
    dispose()
    {
        if(this.canvas)
        {
            this.canvas.removeEventListener('click', this.onMouseClick, false);
            this.canvas.removeEventListener('mousemove', this.onMouseMove, false);
        }

        this.sdk.notifier.off('onDisplayReady', this.updateDisplay);

        if(this.isMounted)
        {
            this.sdk.notifier.off('onFrameRendered', this.onFrameRendered);
            this.sdk.notifier.off('OnCamerasUpdated', this.onCameraUpdated);
            this.sdk.notifier.off('onCanvasResized', this.onCanvasResized);
        }
    }

    //--------------------------------------------------------------------------
    onCanvasResized = (width, height) =>
    {
        this.overlayCanvas.width    = width;
		this.overlayCanvas.height   = height;
    }

    //--------------------------------------------------------------------------
    registerClient(user)
    {
        user.avatar = new Image();
        user.avatar.src = user.image;
        this.activeClients.push(user);
    }

    //--------------------------------------------------------------------------
    setClientVisibilityState(clientUUID, state)
    {
        const client = this.activeClients.find(c => c.clientUUID === clientUUID);
        if(client)
        {
            client.hidden = state;
        }
        else
        {
            console.warn(`Cannot find client ${clientUUID} during setClientVisibilityState`);
        }
    }

    //--------------------------------------------------------------------------
    setClientsRadius(radius, minRadius = 0, maxRadius = Infinity)
    {
        this.clientsRadius = radius;
        this.clientsRadiusMin = Math.max(0, minRadius);
        this.clientsRadiusMax = maxRadius;
    }

    //--------------------------------------------------------------------------
    onCameraUpdated = () =>
    {
        const clientViewportMap = this.sdk.engineAPI.cameraAPI.clientViewportsMap;
        const clients           = this.activeClients
            .filter(c => !c.hidden && clientViewportMap.hasOwnProperty(c.clientUUID))
            .map(client =>
        {
            const viewports = clientViewportMap[client.clientUUID];
            return { ...client, viewports };
        });

        const viewports = this.sdk.engineAPI.cameraAPI.getActiveViewports();
        this.visibleClientsPerViewport = [];

        for(const viewport of viewports)
        {
            this.visibleClientsPerViewport.push(
            {
                viewport        : viewport,
                visibleClients  : this.computeVisibleclients(viewport, clients)
            });
        }

        this.updateHoveredClient();
    }

    //--------------------------------------------------------------------------
    onFrameRendered = () =>
    {
        if(this.overlayCanvas.width == 0 || this.overlayCanvas.height == 0)
        {
            return;
        }

        for(const {viewport, visibleClients} of this.visibleClientsPerViewport)
        {
            this.renderVisibleclients(viewport, visibleClients);
        }

        this.originalContext.drawImage(this.overlayCanvas, 0, 0);
    }

    //--------------------------------------------------------------------------
    onClientClicked = (hoveredViewPort, hoveredClient) =>
    {
        this.sdk.engineAPI.cameraAPI.travel(
            hoveredViewPort.viewport,
            hoveredClient.transform.position,
            hoveredClient.transform.orientation,
            20
        );
    }

    //--------------------------------------------------------------------------
    onMouseClick = (event) =>
    {
        const { hoveredViewport, hoveredClient } = this.findClientHovered(event);

        if(!hoveredClient)
        {
            return;
        }

        this.onClientClicked(hoveredViewport, hoveredClient);
    }

    //--------------------------------------------------------------------------
    onMouseMove = (event) =>
    {
        this.lastMouseMoveEvent = event;
    };

    updateHoveredClient() {
        if (!this.lastMouseMoveEvent) return;

        for(const { visibleClients } of this.visibleClientsPerViewport)
        {
            visibleClients.forEach((client) => {
                this.hoveredClientUUIDs.delete(client.clientUUID);
            });
        }

        const { hoveredClient } = this.findClientHovered(this.lastMouseMoveEvent);
        if(hoveredClient)
        {
            this.hoveredClientUUIDs.add(hoveredClient.clientUUID);
            this.canvas.style.cursor = "pointer";
        }
        else
        {
            this.canvas.style.cursor = "";
        }
    }

    //--------------------------------------------------------------------------
    findClientHovered(event)
    {
        const offset = this.canvas.getBoundingClientRect();
        const position = [
            event.pageX - offset.left,
            event.pageY - offset.top
        ];

        let hoveredClient = null;
        const hoveredViewport = this.visibleClientsPerViewport.find((entry) =>
        {
            return entry.viewport.isInArea(position);
        });

        if(hoveredViewport)
        {
            for(let i = hoveredViewport.visibleClients.length - 1 ; i >= 0; --i)
            {
                const client  = hoveredViewport.visibleClients[i];
                const radius  = this.computeRadius(client);

                if(Math.abs(client.projectedTransform.x - position[0]) < radius
                && Math.abs(client.projectedTransform.y - position[1]) < radius)
                {
                    hoveredClient = client;
                    break;
                }
            }
        }

        return { hoveredViewport, hoveredClient };
    }

    //------------------------------------------------------------------------------
    computeRadius(client)
    {
        const far       = 1000;
        const near      = 0.1;
        const radius    = this.clientsRadius * (far - near)*(1 - client.projectedTransform.z) * 0.1;

        return Math.max(this.clientsRadiusMin, Math.min(this.clientsRadiusMax, radius));
    }

    //--------------------------------------------------------------------------
    computeVisibleclients(viewport, clients)
    {
        const visibleClients  = [];

        if(!this.isExtensionEnabled)
        {
            return visibleClients;
        }

        const [viewportWidth, viewportHeight]   = viewport.getAreaSize();
        const [offsetLeft, offsetTop]           = viewport.getOffset();

        for(const client of clients)
        {
            for(const clientViewport of client.viewports)
            {
                const [projectedX, projectedY, projectedZ]  = viewport.project(clientViewport.transform.position);

                if( projectedX > -viewportWidth  * 0.1 && projectedX < viewportWidth  * 1.1 &&
                    projectedY > -viewportHeight * 0.1 && projectedY < viewportHeight * 1.1 &&
                    projectedZ < 1.0 && projectedZ > 0.0)
                {
                    visibleClients.push(
                    {
                        ...client,
                        transform           : clientViewport.transform,
                        projectedTransform  :
                        {
                            x : projectedX + offsetLeft,
                            y : projectedY + offsetTop,
                            z : projectedZ,
                        }
                    });
                }
            }
        }

        visibleClients.sort( (clientA, clientB) =>
        {
            return clientB.projectedTransform.z - clientA.projectedTransform.z;
        });

        return visibleClients;
    }

    //--------------------------------------------------------------------------
    renderVisibleclients(viewport, visibleClients)
    {
        const dimension = viewport.getAreaSize();
        const offset    = viewport.getOffset();

        this.context.clearRect(offset[0], offset[1], dimension[0], dimension[1]);
        this.context.save();
        this.context.beginPath();
        this.context.rect(offset[0], offset[1], dimension[0], dimension[1]);
        this.context.closePath();
        this.context.clip();

        for(const client of visibleClients)
        {
            const radius = this.computeRadius(client);
            const hovered = this.hoveredClientUUIDs.has(client.clientUUID);

            this.context.beginPath();
            this.context.arc(client.projectedTransform.x, client.projectedTransform.y, radius, 0, 2 * Math.PI, false);
            this.context.closePath();
            this.context.fillStyle = hovered ? 'rgba(0,189,255,0.8)' : 'rgba(0,0,0,0.5)';
            this.context.fill();
            this.context.lineWidth = 2;
            this.context.strokeStyle = client.color ? client.color :'rgba(255,255,255,0.5)';

            this.context.font       = "12px Verdana,Arial,sans-serif";
            this.context.fillStyle  = 'rgba(255,255,255,0.9)';

            if(client.avatar && client.avatar.complete && client.avatar.naturalWidth !== 0)
            {
                this.context.save();
                this.context.beginPath();
                this.context.arc(client.projectedTransform.x, client.projectedTransform.y, radius, 0, 2 * Math.PI, false);
                this.context.closePath();
                this.context.clip();
                this.context.drawImage(
                    client.avatar,
                    client.projectedTransform.x - radius,
                    client.projectedTransform.y - radius,
                    radius*2,
                    radius*2
                );
                this.context.restore();
            }

            if(hovered)
            {
                const text = this.context.measureText(client.displayName);
                this.context.fillText(
                    client.displayName,
                    client.projectedTransform.x - text.width / 2,
                    client.projectedTransform.y + radius + 16
                );
            }

            this.context.stroke();
        }

        this.context.restore();
    }
    
   /**
    * Display visual chips with avatars and names of the clients in the session
    * @private
    *
    * @param {object} options
    * @param {object} options.radius
    * @param {GetClientAvatarSrcFunction} options.getClientAvatarSrc Function that returns the URL of the image
    *                                                                to display as the client's avatar
    * @param {GetClientDisplayNameFunction} options.getClientDisplayName Function that returns the name of the client to display
    */
    showClientAvatars({ radius, getClientAvatarSrc, getClientDisplayName }) {
        this.setClientsRadius(radius);

        const updateColorForClient = (client, color) => {
            const colorCss = `#${color}`;
            client.color = colorCss;
            client.image = getClientAvatarSrc({ client, color: colorCss });
            if (client.avatar) {
                client.avatar.src = client.image;
            }
        };

        const knownClients = new Map();

        const registerUser = (user) => {
            if (knownClients.has(user.clientUUID)) return;
            const client = { ...user, displayName: getClientDisplayName(user) };
            updateColorForClient(
                client,
                SDK3DVerse.engineAPI.editorAPI.clientColors[user.clientUUID],
            );
            this.registerClient(client);
            knownClients.set(user.clientUUID, client);
        };

        const sessionKey = SDK3DVerse.streamer.config.connectionInfo.sessionKey;

        const { apiURL, userToken, apiToken } = SDK3DVerse.webAPI;
        const socket = new WebSocket(
            `${apiURL.replace('http', 'ws').replace(/app\/(v0|v1)/, 'legacy')}/session/notifyWs?sessionKey=${sessionKey}&token=${userToken || apiToken}`,
        );

        socket.onerror = console.error;

        socket.onmessage = async (event) => {
            const message = JSON.parse(event.data);
            const user = message.data;
            switch (message.eventType) {
                case "all-users":
                    user
                        .filter((u) => u.clientUUID !== SDK3DVerse.streamer.clientUUID)
                        .forEach(registerUser);
                    break;
                case "user-joined":
                    if (message.data.clientUUID !== SDK3DVerse.streamer.clientUUID) {
                        registerUser(message.data);
                    }
                break;
            }
        };

        SDK3DVerse.engineAPI.editorAPI.on("client-color", (client) => {
            const knownClient = knownClients.get(client.clientUUID);
            if (knownClient) {
                updateColorForClient(knownClient, client.color);
            }
        });
    }
}

/**
 * @private
 * @function GetClientAvatarSrcFunction
 * @param {object} client The client data
 * @param {string} clientUUID The client unique identifier
 * @param {string} color The color to display for this client in RGB format: "#FFFFFF"
 * @returns {string} The URL of the image to display as the client's avatar
 */

/**
 * @private
 * @function GetClientDisplayNameFunction
 * @param {object} client The client data
 * @param {string} clientUUID The client unique identifier
 * @returns {string} The name of the client to display
 */
